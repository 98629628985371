<template>
  <div class="main">
    <div class="condition-filtrate-box">
      <div class="left-item">
        <el-input class="select-width id-code-width" size="medium" v-model="queryForm.searchValue"
          placeholder="居民姓名/手机号/证件号" />

        <el-select class="select-width" size="medium" v-model="queryForm.status" placeholder="状态" clearable>
          <el-option v-for="(item, v) in statusList" :key="v" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        
        <el-select class="select-width" size="medium" v-model="queryForm.peopleType" placeholder="人员类型" clearable>
          <el-option v-for="item in perTypeList" :key="item.value" :label="item.label" :value="item.value" >
          </el-option>
        </el-select>

        <el-cascader v-model="queryForm.orgId" placeholder="部门" style="width:300px;" :options="cdList" clearable
            :props="{ checkStrictly: true ,label:'orgName',value:'orgId'}" :show-all-levels="false" filterable>
        </el-cascader>

      </div>

      <div class="right-item">
        <el-button type="primary" size="small" @click="queryUserInfo">查询</el-button>
        <el-button type="primary" size="small" @click="addUserInfo" v-btn-permission="'008006004003'">新增</el-button>
      </div>
    </div>

    <el-table height="100%" :data="tableData" row-key="id" default-expand-all border>
      <el-table-column label="操作" width="230" v-btn-permission="'008006004004'">
        <template slot-scope="{ row }">
          <el-link type="primary" @click="editTableFun(row)">编辑</el-link>
          <el-link type="primary" @click="editTableFun2(row)">部门关联</el-link>
          <el-link type="danger" v-if="row.status == 1" @click="editUserStatus(row)">停用</el-link>
          <el-link type="primary" v-else @click="editUserStatus(row)">启用</el-link>
          <!-- <el-link type="danger" v-if="row.status == 1" @click="editUserStatus2(row)">锁定</el-link> -->
          <el-link type="danger" v-if="row.status == 1" @click="editUserStatus2(row)">解锁登录</el-link>

        </template>
      </el-table-column>
      <el-table-column prop="name" label="用户账户"></el-table-column>
      <el-table-column prop="nickName" label="姓名"></el-table-column>
      <el-table-column prop="sex" :formatter="sexFilter" label="性别" width="60" />
      <el-table-column prop="linkPhone" label="手机号码" width="120" />
      <el-table-column prop="cardNo" label="身份证号" width="200" />
      <el-table-column prop="roleName" label="角色" />
      <el-table-column prop="orgName" label="所在部门" />
      <el-table-column prop="status" label="状态">
        <template slot-scope="{ row }">
          <el-tag size="small" v-if="row.status == 1">启用</el-tag>
          <el-tag size="small" type="danger" v-else-if="row.status == 2">停用</el-tag>
        </template>
      </el-table-column>
    </el-table>

    <div class="content-page">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="pageObj.pageNumber" :page-size="pageObj.pageSize" :total="pageObj.total"
        :page-sizes="[10, 20, 30, 50, 100]" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </div>

    <el-dialog title="人员信息" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="600px">
      <el-form :model="formInfo" ref="addForm" label-width="130px" label-position="right" style="width:90%;">
        <!-- {{form.parentId}} -->
        <el-form-item label="真实姓名：" prop="nickName" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model.trim="formInfo.nickName" placeholder="请输入" maxlength="10" />
        </el-form-item>

        <el-form-item label="用户名：" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model.trim="formInfo.name" :disabled="formInfo.id!=null" maxlength="15" placeholder="请输入" />
        </el-form-item>

        <el-form-item label="密码：" prop="pwd" :rules="{ required: formInfo.id==null, message: '不能为空', trigger: 'blur'}">
          <el-input placeholder="请输入密码" v-model.trim="formInfo.pwd" show-password maxlength="16"></el-input>
        </el-form-item>

        <el-form-item label="手机号码：" prop="linkPhone" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model.trim="formInfo.linkPhone" maxlength="11" placeholder="请输入" />
        </el-form-item>

        <el-form-item label="身份证号：" prop="cardNo" :rules="{ required: false, message: '不能为空', trigger: 'blur'}">
          <el-input maxlength="18" v-model.trim="formInfo.cardNo" placeholder="请输入" />
        </el-form-item>

        <el-form-item label="状态：" prop="status" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-radio-group v-model="formInfo.status">
            <el-radio label="1">启用</el-radio>
            <el-radio label="2">停用</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="默认角色：" prop="roleId" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-select v-model="formInfo.roleId" placeholder="请选择" style="width:300px;" clearable>
            <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>

        <el-form-item label="默认所在部门：" prop="orgId" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-cascader v-model="formInfo.orgId" placeholder="输入关键字进行过滤" style="width:300px;" :options="cdList"
            :props="{ checkStrictly: true ,label:'orgName',value:'orgId'}" :show-all-levels="false" filterable clearable>
          </el-cascader>
        </el-form-item>

        <el-form-item label="默认人员类型：" prop="peopleType" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-select v-model="formInfo.peopleType" style="width:300px;" placeholder="请选择">
            <el-option v-for="item in perTypeList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>

      </el-form>
      <div slot="footer" v-if="dialogFormVisibleType !='see'" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogSubmit('addForm')">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="部门关联" :visible.sync="dialogFormVisible2" :close-on-click-modal="false" width="800px">
      <el-table height="550px" :data="tableFrom" row-key="id" default-expand-all border>
        <el-table-column label="部门*"  width="325">
          <template slot-scope="{ row }">
            <el-cascader v-model="row.orgId" placeholder="输入关键字进行过滤" style="width:300px;" :options="cdList"
              :props="{ checkStrictly: true ,label:'orgName',value:'orgId'}" :show-all-levels="false" filterable clearable>
            </el-cascader>
          </template>
        </el-table-column>
        <el-table-column label="角色*" >
          <template slot-scope="{ row }">
            <el-select v-model="row.roleId" placeholder="请选择" style="width:160px;" clearable>
              <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="人员类型*" >
          <template slot-scope="{ row }">
          <el-select v-model="row.peopleType" style="width:160px;" placeholder="请选择">
            <el-option v-for="item in perTypeList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="70" >
          <template slot-scope="scope">
            <el-link type="danger" @click="edittableFrom(scope)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addtableFrom()">添加部门</el-button>
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="submitFormFun2('addForm2')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  const formObj = { //表单
    nickName: "",
    roleId: "",
    isolationPointId: "",
    pwd: "",
    name: "",
    linkPhone: "",
    orgId: "",
    peopleType: "",
    cardNo: "",
    status: "1"
  };

  const perTypeListJSON = [ //表单
    {
      value: "1",
      label: "社区人员"
    },
    {
      value: "2",
      label: "司机"
    },
    {
      value: "3",
      label: "隔离酒店人员"
    },
    {
      value: "4",
      label: "疾控中心人"
    },
    {
      value: "5",
      label: "转运调度人员"
    },
  ];

  const tableFromObj={
    "orgId": "",
    "peopleType": "",
    "roleId": ""
  };

  import {
    getSysOrgTreeListByOrgCode,
    getroleList,
    userList,
    userSave,
    userUpdate,
    userUpdateStaus,
    userSaveUnlock,
    getUserOrgRelationList,
    saveUserOrgRelation
  } from "../../api/SystemManagement/index.js";
  import constants from "../../common/constants";
  import {
    mapState
  } from "vuex";

  export default {
    name: "UserManage",
    components: {

    },
    data() {
      return {
        userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
        tableData: [], //表单数据
        pageObj: {
          pageNumber: 1,
          pageSize: 20,
          total: 0,
        },
        loading: false,
        queryForm: {
          searchValue: "",
          status: "",
          orgId: "",
          orgCode: "",
          peopleType:""
        },
        statusList: constants.USER_STATUS,
        cdList: [],
        dialogFormVisible: false,
        dialogFormVisibleType: 'add',
        formInfo: formObj,
        dialogFormVisible2: false,
        dialogFormVisible2Id:"",
        roleList: [],
        perTypeList: perTypeListJSON,
        tableFrom:[],

      };
    },

    computed: {
      ...mapState({
        // isolationList: state => state.user.isolationList
      }),
    },

    created() {
      this.getUserInfo();
      this.cdListFun();
      this.getRoleList();
    },

    methods: {
      sexFilter(row) {
        let sex = row.sex;
        const typeMap = {
          '1': "男",
          '0': "女",
        };
        return typeMap[sex] || "未知";
      },
      cdListFun() {
        getSysOrgTreeListByOrgCode().then((res) => {
          let {code,data,msg} = res.data;
          if (code == 200) {
            this.cdList = data;
          }
        });
      },
      getUserInfo() {
        let params = Object.assign({}, this.queryForm, this.pageObj);
        if (Object.prototype.toString.call(params.orgId) === '[object Array]') {
          params.orgId = params.orgId[params.orgId.length - 1]
        }
        userList({
          params,
        }).then((res) => {
          let {
            code,
            data,
            msg
          } = res.data;
          if (code == 200) {
            this.tableData = data.list;
            this.pageObj.total = Number(data.totalRow);
          }
        });
      },

      // 角色列表
      getRoleList() {
        getroleList().then(res => {
          let {
            code,
            data,
            msg
          } = res.data;
          console.log("getRoleList -> res.data", res.data);
          if (code == 200) {
            this.roleList = data;
          }
        });
      },

      //查询
      queryUserInfo() {
        this.getUserInfo();
      },
      //新增
      addUserInfo() {
        this.dialogFormVisible = true;
        this.dialogFormVisibleType = 'add';
        this.formInfo = Object.assign({}, formObj);
      },

      //table操作
      //修改
      editTableFun(row, type) {
        console.log(row);
        this.dialogFormVisible = true;
        type ? this.dialogFormVisibleType = type : this.dialogFormVisibleType = 'set';
        this.formInfo = Object.assign({}, row);
      },
      //修改
      editTableFun2(row, type) {
        // console.log(row);
        let params ={userId: row.id};
        this.dialogFormVisible2 = true;
        this.dialogFormVisible2Id = row.id;
        getUserOrgRelationList({
          params
        }).then((res) => {
          let {
            code,
            data,
            msg
          } = res.data;
          if (code == 200) {
            this.tableFrom = data;
          }
        })
      },
      addtableFrom(){
        this.tableFrom.push(Object.assign({},tableFromObj))
      },
      edittableFrom(scope){
        this.tableFrom.splice(scope.$index,1);
      },

      // 编辑用户状态
      editUserStatus(row) {
        this.$confirm(`确定要${row.status=="2"?'启用':'停用'}【${row.name}】吗？`, '操作确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let params = {
              id: row.id,
              status: row.status == "1" ? "2" : "1"
            };
            userUpdateStaus(params).then((res) => {
              let {
                code,
                data,
                msg
              } = res.data;
              if (code == 200) {
                this.$message({
                  type: 'success',
                  message: '修改成功!'
                });
                this.queryUserInfo();
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消修改'
            });
          });
      },

      // 用户锁定状态
      editUserStatus2(row) {
        this.$confirm(`确定要解锁【${row.name}】吗？`, '操作确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let params = Object.assign({
              name: row.name
            });
            userSaveUnlock(params).then((res) => {
              let {
                code,
                data,
                msg
              } = res.data;
              if (code == 200) {
                this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
                this.queryUserInfo();
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消操作'
            });
          });
      },

      //表单新增
      dialogSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
              this.submitFormFun();
          } else {
            return false;
          }
        });
      },
      submitFormFun() {
        if (Object.prototype.toString.call(this.formInfo.orgId) === '[object Array]') {
          this.formInfo.orgId = this.formInfo.orgId[this.formInfo.orgId.length - 1]
        }
        let params = Object.assign({}, this.formInfo);
        // console.log("🚀 ~ file: mechanismManage.vue ~ line 431 ~ submitFormFun ~ params", params)
        var API = this.dialogFormVisibleType == 'add' ? userSave : userUpdate;
        API(params).then((res) => {
          let {
            code,
            data,
            msg
          } = res.data;
          if (code == 200) {
            this.dialogFormVisible = false;
            this.$message({
              type: 'success',
              message: '保存成功!'
            });
            this.queryUserInfo();
          }
        })
      },
      submitFormFun2() {
        console.log(this.tableFrom);
        let arr = this.tableFrom.map((item)=>{
          if (Object.prototype.toString.call(item.orgId) === '[object Array]') {
            item.orgId = item.orgId[item.orgId.length - 1];
          }
          return item
        })
        
        // //必填验证
        // let flag = arr.every((item)=>{
        //   return (item.orgId && item.peopleType && item.roleId)
        // })
        // if(!flag){
        //   this.$message({
        //     type: 'error',
        //     message: '请将数据填写完整!'
        //   });
        //   return
        // }

        let params = {userId:this.dialogFormVisible2Id,sysUserOrgRelationList:arr};
        console.log("🚀 ~ file: mechanismManage.vue ~ line 451 ~ submitFormFun ~ params", params)
        saveUserOrgRelation(params).then((res) => {
          let {
            code,
            data,
            msg
          } = res.data;
          if (code == 200) {
            this.dialogFormVisible2 = false;
            this.$message({
              type: 'success',
              message: '保存成功!'
            });
            this.queryUserInfo();
          }
        })
      },

      //分页变化
      handleSizeChange(val) {
        this.pageObj.pageSize = val;
        this.getUserInfo();
      },
      handleCurrentChange(val) {
        this.pageObj.pageNumber = val;
        this.getUserInfo();
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "../../static/css/condition-filtrate.scss";

  .main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .el-table {
      overflow-y: auto;
    }
  }

  .content-page {
    margin-top: 10px;
    height: 40px;
    display: flex;
    justify-content: flex-end;
  }

  .el-link {
    margin-right: 10px;
  }
</style>

<style scoped>
  .el-table>>>td {
    padding: 6px 0 !important;
  }
</style>